.popup {
    position: fixed;
    top: 100px;
    left: 20rem;
    width: 60%;
    height: 80%;
    background-color: rgb(218, 218, 218);
    border-radius: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* .popup_inner {
    position: relative;
    padding: 55px;
    background-color: #fff;
} */

.popup_inner .Cbtn {
    position: absolute;
    top: 16px;
    right: 16px;
}

.Cbtn {
    font-size: 2rem;
}